import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RenderHeaderService } from 'src/app/services/render-header.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {

  mostrarIcono: boolean = false;
  private subscription: Subscription;

  constructor(private renderHeader: RenderHeaderService, private router: Router,) {
    this.subscription = this.renderHeader.mostrarIcono$.subscribe(
      valor => {
        this.mostrarIcono = valor;
      }
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  irHome() {
    const trabId = localStorage.getItem('idTrabajador');
    this.router.navigate([`/ver-trabajador/${trabId}`]);
  }
}
