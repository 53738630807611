import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RenderHeaderService {
  private mostrarIcono = new BehaviorSubject<boolean>(false);
  mostrarIcono$ = this.mostrarIcono.asObservable();

  constructor() { }

  actualizarMostrarIcono(valor: boolean) {
    this.mostrarIcono.next(valor);
  }
}
