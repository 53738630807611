import {Injectable} from '@angular/core';

// import {Credentials} from 'aws-sdk/global';
// import {CredentialsCognitoIdentityClientConfig} from 'aws-sdk/lib/service';
// import {LoginsMap} from 'aws-sdk/clients/cognitoidentity';
import {Credentials, CognitoIdentityClientConfig, CognitoIdentity, GetCredentialsForIdentityCommandInput} from '@aws-sdk/client-cognito-identity';
import {CognitoUserPool} from 'amazon-cognito-identity-js';

import {environment} from '../../environments/environment';

export interface CognitoCallback {
  cognitoCallback(message: string, result: any): void;

  handleMFAStep?(challengeName: string, challengeParameters: ChallengeParameters, callback: (confirmationCode: string) => any): void;
}

export interface LoggedInCallback {
  isLoggedIn(message: string, loggedIn: boolean): void;
}

export interface ChallengeParameters {
  CODE_DELIVERY_DELIVERY_MEDIUM: string;

  CODE_DELIVERY_DESTINATION: string;
}

export interface Callback {
  callback(): void;

  callbackWithParam(result: any): void;
}

@Injectable({
  providedIn: 'root'
})
export class CognitoUtilService {

  constructor() {
  }

  public static REGION = environment.REGION;
  public static IDENTITY_POOL_ID = environment.IDENTITY_POOL_ID;
  public static USER_POOL_ID = environment.USER_POOL_ID;
  public static CLIENT_ID =  environment.CLIENT_ID;

  public static POOL_DATA: any = {
    UserPoolId: CognitoUtilService.USER_POOL_ID,
    ClientId: CognitoUtilService.CLIENT_ID
  };

  public cognitoCreds!: Credentials;

  getUserPool() {
    if (environment.IDENTITY_POOL_ID) {
      CognitoUtilService.POOL_DATA.endpoint = environment.IDENTITY_POOL_ID;
    }

    return new CognitoUserPool(CognitoUtilService.POOL_DATA);
  }

  getCurrentUser() {
    return this.getUserPool().getCurrentUser();
  }

  setCognitoCreds(creds: Credentials) {
    this.cognitoCreds = creds;
  }

  // This method takes in a raw jwtToken and uses the global AWS config options to build a
  // Credentials object and store it for us. It also returns the object to the caller
  // to avoid unnecessary calls to setCognitoCreds.
  async buildCognitoCreds(idTokenJwt: string) {
    let url = 'cognito-idp.' + CognitoUtilService.REGION.toLowerCase() + '.amazonaws.com/' + CognitoUtilService.USER_POOL_ID;
    if (environment.IDENTITY_POOL_ID) {
      url = environment.COGNITO_IDP_ENDPOINT + '/' + CognitoUtilService.USER_POOL_ID;
    }

    const logins: Record<string, string> = {};
    logins[url] = idTokenJwt;
    const params: GetCredentialsForIdentityCommandInput = {
      IdentityId: CognitoUtilService.IDENTITY_POOL_ID, /* required */
      Logins: logins
    };

    const serviceConfigs = {} as CognitoIdentityClientConfig;
    if (environment.COGNITO_IDENTITY_ENDPOINT) {
      serviceConfigs.endpoint = environment.COGNITO_IDENTITY_ENDPOINT;
    }

    const client = new CognitoIdentity(serviceConfigs);
    const creds = await client.getCredentialsForIdentity(params)
                        .then((res) => res.Credentials);
    this.setCognitoCreds(creds!);

    return creds;
  }

  refresh(): void {
    this.getCurrentUser()?.getSession((err: any, session: any) => {
      if (err) {
        console.log('CognitoUtil: Can\'t set the credentials:' + err);
      } else {
        if (session.isValid()) {
          console.log('CognitoUtil: refreshed successfully');
        } else {
          console.log('CognitoUtil: refreshed but session is still not valid');
        }
      }
    });
  }

}
