import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthInterceptor } from '../interceptors/auth.interceptor';
import { ToastrService } from 'ngx-toastr';
import { AuthGuard } from '../_guards';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    navigate:string = '';
    constructor(private authenticationService: AuthInterceptor,
                private router: Router,
                private toastr: ToastrService,
                private authGuard: AuthGuard
                ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        console.log('request HttpRequest', request);
        console.log('next HttpHandler', next)
        const newReq = request.clone();
        return next.handle(request).pipe(catchError(err => {
            console.log('next.handle', request);
            console.log('error interceptor', err);
            if (err.status === 401 || err.status === 0 ) {
                this.authGuard.refreshtoken();
            } else if ( err.status === 502 || err.status === 501) {
                this.toastr.error('Ocurrio un error en la peticion de información al servidor, favor vuelva a intentar.');
            } else if (err.status === 504) {
                if(this.router.url.includes('token')){
                    const [basePath,queryParams] = this.router.url.split('?');
                    this.navigate = `${basePath}${queryParams ? '#' + queryParams : ''}`;
                }
                localStorage.clear();
                this.toastr.error('Su sesión ha caducado.');
                this.router.navigate(['/login']);
            }

            const error: HttpErrorResponse = err;
            error.error.message = error.error.message || error.statusText;
            return throwError(error);
        }));
    }
}
