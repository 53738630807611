import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { LocalStorageService } from 'angular-web-storage';
import { CognitoUtilService } from './cognito-util.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private cognitoUtil: CognitoUtilService,
    private router: Router) { }

  isAuthenticated(callback: any) {

    if (callback == null) {
      throw new Error(('UserLoginService: Callback en isAuthenticated() no puede ser null'));
    }

    const cognitoUser = this.cognitoUtil.getCurrentUser();

    console.log(cognitoUser);

    if (cognitoUser != null) {
      cognitoUser.getSession((err: any, session: any) => {
        if (err) {
          console.log('UserLoginService: No se logro recuperar la sesion: ' + err, err.stack);
          callback.isLoggedIn(err, false);
        } else {
          console.log('session:' + session);
          console.log('UserLoginService:' + session.isValid());
          callback.isLoggedIn(err, session.isValid());
        }
      });
    } else {
      console.log('callback',callback);
      console.log('UserLoginService: no se logro recuperar el CurrentUser');
      callback.isLoggedIn('No se logro recuperar el CurrentUser', false);
    }
  }

  loginUsuario(rut: string, pass: string): Observable<any[]> {
    const data = {
      username: rut,
      password: pass,
      client_id: environment.CLIENT_ID,
      auth_flow: 'implicit'
    }

    console.log('data de servicio login: ',data);

    const body = JSON.stringify(data);
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(`${environment.API_DOMAINS.USUARIOS}`,body, { headers });
  }

  refrestoken(token: any): Observable<any> {
    const body = JSON.stringify(token);
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${environment.API_DOMAINS.USUARIOS}/tok/reftok`, body, {headers});
  }
  
  logout(){
    localStorage.clear();
    this.router.navigate(['/login']);
  }
}
