<ngx-spinner bdColor="rgba(29,35,69,0.5)" size="medium" color="#FC882E" type="ball-fussion" [fullScreen]="true">
  <span class="text-18-bold text-white"> Por favor espera...</span>
</ngx-spinner>

<div style="height: 100vh; background: #0B58A4">
  <div>
    <app-header></app-header>
    <div style="background: #ffffff">
      <router-outlet></router-outlet>
    </div>
    <div>
      <app-footer style="position: relative; bottom: 0;"></app-footer>
    </div>
  </div>
</div>
