import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_guards/auth.guard';

const routes: Routes = [
  {
    path: '', redirectTo: 'ver-trabajador/:id', pathMatch: 'full'
  },
  {
    path: 'ver-trabajador/:id', loadChildren: () => import('./components/public/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'login', loadChildren: () => import('./components/public/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'private/ver-trabajador/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./components/private/page-persona/page-persona.module').then(m => m.PagePersonaModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
