import {Injectable, Output, EventEmitter} from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LoginService} from '../services/login.service';
import {Observable, Subject} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthGuard implements CanActivate {
    // tslint:disable-next-line:variable-name
    _userActionOccured: Subject<void> = new Subject();
    get userActionOccured(): Observable<void> { return this._userActionOccured.asObservable(); }
    // tslint:disable-next-line:variable-name
    @Output() getLoggedInUser: EventEmitter<any> = new EventEmitter();
    // tslint:disable-next-line:variable-name
    public _counter = 0;
    // tslint:disable-next-line:variable-name
    public _status = 'Initialized.';
    // tslint:disable-next-line:variable-name
    private _navigate?: string;


    constructor(private router: Router,
                private userLoginService: LoginService,
                private toastr: ToastrService,
                ) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if(route.data["redirect"]){
            this._navigate = `/${route.routeConfig?.path}${(route.queryParams) ? '#' + Object.entries(route.queryParams).map(([key,value]) => `${key}=${encodeURIComponent(value)}`).join('&') : ''}`
        }
        if(route.fragment && route.fragment.includes('accessToken')){
            await this.router.navigate(['/auth'],{
                fragment:route.fragment})
        }else{
            this.userLoginService.isAuthenticated(this);
        }
      return true;
    }
// isLoggedIn
    isLoggedIn(message: string, isLoggedIn: boolean) {

        if (!isLoggedIn) {
            this.logout();
        }
    }

    async refreshtoken() {
        // se solicita actualizar token
        const ci = environment.CLIENT_ID;
        const retoken = localStorage.getItem('refreshToken');
        const token = {
            app_id: ci ,
            token: retoken
        };
        this.userLoginService.refrestoken(token).subscribe( (response: any) => {
            const { idToken, accessToken } = response;
            //Auth
            localStorage.setItem('idToken', idToken);
            localStorage.setItem('accessToken', accessToken);
        }, (error: any) => {
            console.log(error);
            this.toastr.warning('Ocurrió un error en su sesión de usuario y deberá reiniciarla ');
            this.logout();
        });
    }

    logout() {
        localStorage.clear();
        this.toastr.error('Su sesión ha caducado.');
        this.router.navigate(['/login']);
    }
}
