<!-- vista footer -->
<div class="container-fluid">
  <div class="row py-4 box__preguntas">
    <div class="col-6 pe-1 text-end">
      <img src="../assets/img/logo_consulta_footer.svg" alt="" width="80px">
    </div>
    <div class="col-6 ps-1 box__consper">
      <div>
        <span class="text-14-bold text-white">CONSULTA</span>
      </div>
      <div>
        <span class="text-14-bold text-calypso">PERSONAS</span>
      </div>
    </div>
    <div class="col-12 text-center">
      <span class="text-10 text-white">Un sistema de OTIC SOFOFA</span>
    </div>
    <div class="col-12 mt-5 img__rrss">
      <img src="../assets/img/logo_facebook.svg" alt="" width="20px">
      <img src="../assets/img/twiter_footer.svg" alt="" width="20px">
      <img src="../assets/img/instagram_footer.svg" alt="" width="20px">
    </div>
  </div>
</div>
